import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Header, Radio } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  ForgotPasswordModal,
  Loading,
  Primary as SignInButton,
  Seo,
  StyledInput,
  Container,
  ThemedIcon,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
// Hooks
import useValidateExistingUser from '../hooks/useValidateExistingUser'
import useShowError from '../hooks/useShowError'
// Styles
import {
  QLogo,
  StyledForm,
  StyledSpan,
  Wrapper,
} from '../styles/LoginPage.styles'
import { StyledCheckboxLabel } from '../styles/RegisterPage.styles'
// Utils & Services
import { buildSignUpSchema, buildSignInSchema } from '../utils/validations'
import LoginErrorModal from '../components/LoginErrorModal/LoginErrorModal'

const LoginPage = () => {
  const {
    prismicData: {
      prismicGeneral,
      prismicLoginPage: {
        confirm_password_placeholder,
        email_placeholder,
        forget_your_password,
        logo,
        password_placeholder,
        sign_in,
        sign_up,
        signing_in,
        title,
        to_get_started,
        welcome,
      },
      prismicHomepage: { event_image },
      prismicExistingQModal: {
        are_you_an_existing_q_science_customer,
        no,
        yes,
      },
    },
  } = usePrismic()

  const {
    handleFirebaseLogin,
    isAuthenticated,
    isAuthLoading,
    isEventSite,
    isVipSite,
    isVerified,
    qLoginIssue,
    manageReferral: { isReferral, referralData },
  } = useAuthContext()

  const { cartData, isPcOfferInCart } = useCartContext()

  const [verifyAccountScreens, step] = useValidateExistingUser()
  const [error, setError, showError] = useShowError()

  const [isNewSignUp, setIsNewSignUp] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const setIsNewSignUpToStateAndStorage = value => {
    setIsNewSignUp(value)
    localStorage.setItem('isNewSignUp', value ? 'true' : 'false')
  }

  const getIsNewSignUpFromStorage = () => {
    const value = localStorage.getItem('isNewSignUp')
    if (value === null) return setIsNewSignUp(false)
    setIsNewSignUp(value === 'true' ? true : false)
  }

  useEffect(() => {
    getIsNewSignUpFromStorage()
  }, [])

  useEffect(() => {
    if (isReferral || isPcOfferInCart) setIsNewSignUpToStateAndStorage(true)
  }, [isReferral, isPcOfferInCart])

  useEffect(() => {
    if (isAuthenticated && isVerified) {
      if (referralData?.type === 'ambassador') return navigate('/enrollment')
      if (cartData?.items.length) navigate('/cart')
      else navigate('/subscriptions')
    }

    if (isAuthenticated) {
      setIsNewSignUpToStateAndStorage(false)
    }
  }, [isAuthenticated, isVerified, cartData, referralData])

  useEffect(() => {
    if (qLoginIssue) setOpenErrorModal(true)
  }, [qLoginIssue])

  const handleSetResolvers = () =>
    isNewSignUp
      ? { resolver: yupResolver(buildSignUpSchema(prismicGeneral)) }
      : { resolver: yupResolver(buildSignInSchema(prismicGeneral)) }

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(handleSetResolvers())

  const onSubmit = async (data: { email: string; password: string }) => {
    const { email, password } = data
    const handleError = e => {
      setError(e)
      reset()
    }
    handleFirebaseLogin({ email, password, isNewSignUp, handleError })
  }

  const displayWelcome = () => {
    let welcomeText = welcome[0].text
    if (referralData?.firstname && referralData?.lastname) {
      return `${welcomeText} ${referralData?.firstname} ${referralData?.lastname}`
    }
    if (referralData?.firstname && !referralData?.lastname) {
      return `${welcomeText} ${referralData?.firstname}`
    }
    return `${welcomeText} to the Q family`
  }

  const isEventOrVip = isEventSite || isVipSite

  if (isAuthLoading)
    return <Loading loading={true} message={signing_in[0].text} />

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        {isAuthenticated && !isVerified && verifyAccountScreens[step]}
        <Container direction="column" maxWidth="500px">
          <Container margin="0 0 3rem 0">
            <QLogo
              src={isEventOrVip ? event_image.url : logo.url}
              width={isEventOrVip ? '500px' : '250px'}
            />
          </Container>
          {isReferral && referralData ? (
            <>
              <StyledSpan welcome>{displayWelcome()}</StyledSpan>
              <StyledSpan welcome>{to_get_started[0].text}</StyledSpan>
            </>
          ) : null}
          {/*<Container direction="column">
            <Header style={{ textAlign: 'center' }}>
              {are_you_an_existing_q_science_customer[0].text}
            </Header>
            <Container>
              <Radio
                checked={!isNewSignUp}
                label={<StyledCheckboxLabel>{yes[0].text}</StyledCheckboxLabel>}
                name="q-member"
                onChange={() => setIsNewSignUpToStateAndStorage(false)}
                style={{ marginRight: '12px' }}
              />

              <Radio
                checked={isNewSignUp}
                label={<StyledCheckboxLabel>{no[0].text}</StyledCheckboxLabel>}
                name="new-user"
                onChange={() => setIsNewSignUpToStateAndStorage(true)}
                style={{ marginLeft: '12px' }}
              />
            </Container>
          </Container>*/}
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {!!error && showError(errors)}
            <Container direction="column" align="flex-start">
              <StyledSpan error>{errors.email?.message}</StyledSpan>
              <StyledInput
                {...register('email')}
                placeholder={email_placeholder[0].text}
                onFocus={() => setError(null)}
                data-qa="emailInput"
              />
            </Container>
            <Container
              direction="column"
              align="flex-start"
              style={{ position: 'relative' }}
            >
              <StyledSpan error>{errors.password?.message}</StyledSpan>
              <StyledInput
                {...register('password')}
                placeholder={password_placeholder[0].text}
                type={showPassword ? null : 'password'}
                data-qa="passwordInput"
              />
              <ThemedIcon
                name={showPassword ? 'hide' : 'unhide'}
                onClick={() => setShowPassword(state => !state)}
                color="black"
                style={{ position: 'absolute', bottom: '2.6rem', right: 8 }}
              />
            </Container>
            {isNewSignUp ? (
              <Container
                direction="column"
                align="flex-start"
                style={{ position: 'relative' }}
              >
                <StyledSpan error>{errors.passwordConfirm?.message}</StyledSpan>
                <StyledInput
                  {...register('passwordConfirm')}
                  placeholder={confirm_password_placeholder[0].text}
                  type={showConfirmPassword ? null : 'password'}
                  data-qa="passwordConfirmInput"
                />
                <ThemedIcon
                  name={showConfirmPassword ? 'hide' : 'unhide'}
                  onClick={() => setShowConfirmPassword(state => !state)}
                  color="black"
                  style={{ position: 'absolute', bottom: '2.6rem', right: 8 }}
                />
              </Container>
            ) : null}
            <SignInButton
              type="submit"
              disabled={Object.entries(errors).length !== 0}
              data-qa="submitButton"
            >
              {isNewSignUp ? sign_up[0].text : sign_in[0].text}
            </SignInButton>
          </StyledForm>
          <Container justify="space-between" padding="10px 0 0 0">
            <span style={{ marginTop: '10px' }}>
              {forget_your_password[0].text}
            </span>
            <ForgotPasswordModal data-qa="forgotPassord" resetLogin={reset} />
          </Container>
          <Container>
            <LoginErrorModal
              open={openErrorModal}
              onCloseModal={() => setOpenErrorModal(false)}
            />
          </Container>
        </Container>
      </Wrapper>
    </>
  )
}

export default LoginPage
